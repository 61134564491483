.anim-fade-in-rise-up {
  opacity: 0;
  transform: translateY(8px);
  animation: rise-up 280ms ease-out forwards, fade-in 240ms ease-out forwards;
}

.anim-fade-in-fall-down {
  opacity: 0;
  transform: translateY(-8px);
  animation: fall-down 280ms ease-out forwards, fade-in 240ms ease-out forwards;
}

.anim-fade-in-slide-right {
  opacity: 0;
  transform: translateX(-8px);
  animation: slide-right 280ms ease-out forwards,
    fade-in 240ms ease-out forwards;
}

.anim-fade-in-slide-left {
  opacity: 0;
  transform: translateX(-8px);
  animation: slide-left 280ms ease-out forwards, fade-in 240ms ease-out forwards;
}

.anim-slide-up {
  transform: translateY(8px);
  animation: slide-up 280ms ease-out forwards;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(8px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-8px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fall-down {
  from {
    transform: translateY(-8px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes rise-up {
  from {
    transform: translateY(8px);
  }

  to {
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fade-in 240ms ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


@keyframes background-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
