.overflow-visible {
  overflow: visible !important;
}

.d-flex {
  display: flex;
}

.gap-3 {
  gap: 1rem;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.d-block {
  display: block;
}
