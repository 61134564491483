* {
  box-sizing: border-box;
  overscroll-behavior: none;
}

:root {
  --content-max-width: 1200px;
  touch-action: pan-x pan-y;
  height: 100%
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

#portal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  overflow: hidden;
  pointer-events: none;
}

#portal-container>* {
  pointer-events: all;
}


body>#webpack-dev-server-client-overlay {
  display: none;
}