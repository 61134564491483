:root {
  /* Border radius */
  --border-radius-sm: 0.5rem;
  --border-radius-md: 1rem;
  --border-radius-lg: 1.5rem;
  --border-radius-round: 20rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.m-0 {
  margin: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mx-3 {
  margin-inline: 1rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-3 {
  padding: 1rem !important;
}

.px-2 {
  padding-inline: 0.5rem !important;
}

.px-3 {
  padding-inline: 1rem !important;
}

.py-1 {
  padding-block: 0.25rem !important;
}

.py-2 {
  padding-block: 0.5rem !important;
}

.py-3 {
  padding-block: 1rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.px-3 {
  padding-inline: 1rem !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.py-0 {
  padding-block: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}
