:root {
  --clr-neutral-solid-0: #f8f9fb;
  --clr-neutral-solid-25: #f6f8fa;
  --clr-neutral-solid-50: #eceff3;
  --clr-neutral-solid-100: #dfe1e6;
  --clr-neutral-solid-200: #c1c7cf;
  --clr-neutral-solid-300: #a4abb8;
  --clr-neutral-solid-400: #808897;
  --clr-neutral-solid-500: #666d80;
  --clr-neutral-solid-600: #31353f;
  --clr-neutral-solid-700: #272835;
  --clr-neutral-solid-800: #1a1b25;
  --clr-neutral-solid-900: #0d0d12;

  --clr-background-white: #fff;
  --clr-background-primary: #fff;
  --clr-background-disabled: #f8fafb;
  --clr-background-0: #f8f9fb;
  --clr-background-25: #f6f8fa;
  --clr-background-50: #eceff3;
  --clr-background-100: #dfe1e6;
  --clr-background-900: #0d0d12;
  --clr-background-primary-lighter: #ebf1ff;
  --clr-background-ukwn-1: #fafafa;
  --clr-background-ukwn-2: #f5f5f5;
  --clr-background-ukwn-3: #a19df1;
  --clr-background-ukwn-4: #dfe1e7;
  --clr-background-ukwn-5: #f9a80a;
  --clr-background-ukwn-6: #e7e7e7;
  --clr-background-ukwn-7: #f9f9f9;
  --clr-background-ukwn-8: #fbfbfb;
  --clr-background-ukwn-9: #000005;
  --clr-background-ukwn-10: #e5f7ad;
  --clr-background-ukwn-11: #fff0f3;
  --clr-background-ukwn-12: #e8fff4;

  --clr-primary-0: #f0efff;
  --clr-primary-25: #e5e4fb;
  --clr-primary-50: #a19cf0;
  --clr-primary-100: #5f57ff;
  --clr-primary-200: #312ba0;
  --clr-primary-300: #201c6c;
  --clr-primary-light: #7e7bc3;

  --clr-icon-300: #a4abb8;
  --clr-icon-400: #818898;
  --clr-icon-500: #666d80;
  --clr-icon-600: #353849;
  --clr-icon-900: #0d0d12;

  --clr-icon-text-200: #818898;

  --clr-text-white: #fff;
  --clr-text-300: #a4abb8;
  --clr-text-400: #808897;
  --clr-text-500: #666d80;
  --clr-text-600: #353849;
  --clr-text-900: #0d0d12;
  --clr-text-ukwn-1: #171717;
  --clr-text-ukwn-2: #1a1d1f;
  --clr-text-ukwn-3: #1e75ff;
  --clr-text-ukwn-4: #242b42;
  --clr-text-ukwn-5: #7e8ca0;
  --clr-text-ukwn-6: #1d1e25;
  --clr-text-ukwn-7: #808d9e;
  --clr-text-ukwn-8: #0f172a;
  --clr-text-ukwn-9: #878787;

  --clr-border-25: #f6f8fa;
  --clr-border-50: #eceff3;
  --clr-border-100: #dfe1e6;
  --clr-border-200: #c1c7cf;
  --clr-border-ukwn-1: #f5f5f5;
  --clr-border-ukwn-2: #efefef;
  --clr-border-ukwn-3: #f1f0f3;
  --clr-border-ukwn-4: #e9e9e9;
  --clr-border-ukwn-5: #dfe1e7;
  --clr-border-ukwn-6: #e5e5e5;
  --clr-border-ukwn-7: #a19df1;
  --clr-border-ukwn-8: #f8f8fb;
  --clr-border-ukwn-9: #f0f5f9;
  --clr-border-ukwn-10: #ccc;
  --clr-border-ukwn-11: #f2f2f2;
  --clr-border-ukwn-12: #f3f3f3;
  --clr-border-ukwn-13: #000005;
  --clr-border-ukwn-14: #f4f5f6;
  --clr-border-ukwn-15: #ecf1f2;
  --clr-border-ukwn-16: #8fe7be;
  --clr-border-ukwn-17: #ed8296;

  --clr-ukwn-1: #36394a;
  --clr-ukwn-2: #c1c7d0;
  --clr-ukwn-3: #a4acb9;

  --clr-error-400: #df1c41;
  --clr-error-500: #ea5b5b;
  --clr-success-500: #1dbf73;

  --filter-primary-500: brightness(0) invert(40%) sepia(87%) saturate(4402%)
    hue-rotate(232deg) brightness(99%) contrast(103%);
  --filter-white: brightness(0) invert(1);
  --filter-gray: brightness(0) invert(56%) sepia(10%) saturate(498%)
    hue-rotate(184deg) brightness(93%) contrast(87%);

  --clr-secondary-yellow-50: #fcda83;

  --clr-extra-peach: #ebc5bd;
  --clr-extra-marker-green: #e5f7ad;
  --clr-extra-purple: #cac2ff;
}

body {
  color: var(--clr-text-900) !important;
  background: var(--clr-background-primary);
  transition: background 240ms ease-in;
}

.color-error {
  color: var(--clr-error-500) !important;
}

.color-primary-100 {
  color: var(--clr-primary-100, #5f57ff) !important;
}

hr {
  display: block;
  background-color: var(--clr-border-ukwn-4, #e9e9e9);
  height: 1px;
  width: 100%;
  border: 0;
}
